.print-banner {
    display: block;
}

.cookies-top-bloc,
.menu-skip,
.header,
.menu-main,
.menu-stratis,
.top-of-content,
.go-to-top,
.menu-cross,
.newsletter,
.stratis,
.section-main__aside,
.filters,
.menu-main-1 {
    display: none !important;
}

.wrapper-main {
    width: 100%;
    margin: 0;
    padding: 0;
}

.ddm__sub-level {
    display: block !important;
    position: static !important;
    top: inherit !important;
    right: inherit !important;
    bottom: inherit !important;
    left: inherit !important;
    height: auto !important;
    max-height: 100% !important;
}

.list {
    margin-top: 3em;
}

/* image of typo3 */
.ce-right .ce-gallery, .ce-left .ce-gallery, .ce-column {
    float: none;
}
.ce-gallery figure, .ce-gallery figcaption {
    display: block;
}
.ce-gallery img {
    width: auto;
}
.ce-gallery .ce-column {
    margin-bottom: 2rem;
}

/* Photos gallery */
.carousel-galerie {
    display: none !important;
}

.slider-galerie {
    .slider-galerie__item {
        display: block !important;
        position: static !important;
        opacity: 1 !important;
        visibility: visible !important;
        margin-bottom: 2rem;
        background: none;
    }
    .cycle-sentinel {
        display: none !important;
    }
    a {
        padding: 0;
        figure {
            visibility: visible !important;
            display: block;
        }
    }
    img {
        visibility: visible !important;
        max-width: inherit;
        max-height: inherit;
        position: static;
        top: inherit;
        left: inherit;
        bottom: inherit;
        right: inherit;
        transform: inherit;
    }
    .infos-img {
        position: static;
        width: 100%;
        display: block !important;
        background: inherit;
    }
}

.classical-gallery__nb-items {
    display: none !important;
}
